import React,{useState} from 'react'
import "./CarrersForm.css"
import axios from 'axios'
import { Spinner } from 'react-bootstrap'

function CarrersForm() {
    const [fname, setFName] = useState()
    const [lname, setLName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [jobInterested, setJobInterested] = useState("Lead Engineer/Technicians")
    const [cv,setCv] = useState(null)
    const [uploading,setUploading] = useState(false)
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handlePhoneChange = (e) => {
        const numericValue = e.target.value.replace(/\D/g, ''); 
        const maxLength = 25;
    
    
        if (numericValue.length <= maxLength) {
          setPhone(numericValue);
        }
      };
      const cvFileHandler = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("cv", file);
        setUploading(true);
        try {
          const config = { Headers: { "Content-Type": "multipart/form-data" } };
          const { data } = await axios.post("/api/upload/resume", formData, config);
          setCv(data)
          setUploading(false);
        } catch (error) {
          console.error(error);
          setCv(null)
          e.target.value=""
          alert("error occured while uploading your file please make sure it's type is (.pdf or .docx) and is less then 3MB")
          setUploading(false);
        }
      };

      const submitCareerForm = (e) => {
        e.preventDefault();
        setLoading(true);
        if(cv === null)
        {
            setLoading(false)
            alert("Please upload your Resume file")
            return
        }
        axios
          .post(`/job-opportunity-form`, {
            email: email,
            fname: fname,
            lname:lname,
            city:city,
            state:state,
            jobInterested:jobInterested,
            phoneNum:phone,
            resume:cv,
            formType: "job Form",
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Message sent Successfully!");
              setLoading(false);
              // setEmail("");
              // window.open("/thank-you", '_blank');
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Failed to send message, try again by reloading the page.");
              setLoading(false);
              setEmail("");
            }
          });
      };
  return (
    <div className='CarrersForm-container standard-padding-space'>
        <form className='container' onSubmit={submitCareerForm}>
            
              <div className="service-form d-flex flex-column p-4 py-5 mx-auto">
                <div className='text-center'>
                    <h3 className='body-heading'>Fill Out The Form <span className='color-blue'>Below</span></h3>
                    <h4 style={{color:"white"}}>We will be back to you</h4>
                </div>
                <div className='row '>
                  <div className='col-lg-6 pe-lg-4'>
                    <input 
                        type="text" 
                        value={fname}
                        required
                        maxLength="36"
                        onChange={(e) => setFName(e.target.value)}
                        placeholder="Your First Name" 
                        className='w-100'
                      />
                  </div>
                  <div className='col-lg-6 ps-lg-4'>
                    <input 
                        type="text" 
                        value={lname}
                        required
                        maxLength="36"
                        onChange={(e) => setLName(e.target.value)}
                        placeholder="Your Last Name" 
                        className='w-100'
                      />
                  </div>
                </div>

                <input 
                   type="text" 
                   value={email}
                   required
                   maxLength="36"
                   onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email Address" 
                />

                  <input 
                  type="tel" 
                  value={phone}
                  required
                  maxLength="25"
                  onChange={handlePhoneChange}
                  placeholder="Phone Number" 
                />
                    <input 
                        type="text" 
                        value={city}
                        required
                        maxLength="36"
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="Your City" 
                        className='w-100'
                      />

                      <input 
                        type="text" 
                        value={state}
                        required
                        maxLength="36"
                        onChange={(e) => setState(e.target.value)}
                        placeholder="Your State" 
                        className='w-100'
                      />

                      <label className='mb-1 ps-1' style={{marginTop: "28px",fontSize:".8rem",color:"lightGrey"}}>Job Interested</label>
                      <select
                        value={jobInterested}
                        onChange={(e)=>{setJobInterested(e.target.value)}}
                        className='mt-0'
                        >
                        <option value={"Lead Engineer/Technicians"}>
                        Lead Engineer/Technicians
                        </option>
                        <option value={"Field Technicians"}>
                        Field Technicians
                        </option>
                        <option value={"Smart Hands"}>
                        Smart Hands
                        </option>
                        <option value={"Management"}>
                        Management
                        </option>
                      </select>
                
                
                
                  <div className="w-100 text-center mt-4">
                    <label class="file-input-wrapper mx-auto">
                      <input type="file" id="fileInput" onChange={cvFileHandler} />
                      Upload Resume
                    </label>
                  </div>
                

                <button className="blue-btn mt-5" type='submit'>Submit
                {loading ? (
                      <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                      />
                  ) : (
                  success ? (
                      <div>
                      {setLoading(false)}
                      {alert(success)}

                      {setSuccess(false)}
                      </div>
                  ) : (
                      error && (
                      <div>
                          {setLoading(false)}
                          {alert(error)}
                          {setError(false)}
                      </div>
                      )
                  )
                  )}
                </button>
              </div>

        </form>
    </div>
  )
}

export default CarrersForm